var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-white",style:(_vm.width >= 1000 ? 'margin-left:56px;' : '')},[_c('div',{style:(`--colour: ${_vm.getAppColour(false)}`)},[_c('div',{staticClass:"d-flex flex-direction-column align-items-center w-100 border-bottom bg-lightGrey",class:_vm.width >= 576 ? 'justify-content-around' : 'justify-content-between',staticStyle:{"height":"56px","position":"sticky","top":"56px","z-index":"10"}},[(_vm.width >= 576)?_c('h3',{staticClass:"mx-4 my-auto"},[_vm._v("User Settings")]):_c('h5',{staticClass:"font-weight-bolder my-auto ml-4"},[_c('BIcon',{staticClass:"mr-2",attrs:{"icon":"arrow-left"},on:{"click":function($event){_vm.hasHistory
              ? _vm.$router.go(-1)
              : _vm.$router.push(`/${_vm.getAppName(false)}-view`)}}}),_vm._v(" User Settings ")],1),_c('div',{staticClass:"mx-4 my-auto"},[(_vm.width >= 576)?_c('BButton',{attrs:{"variant":"outline-primary px-4 mx-2"},on:{"click":function($event){_vm.hasHistory
              ? _vm.$router.go(-1)
              : _vm.$router.push(`/${_vm.getAppName(false)}-view`)}}},[_vm._v("Cancel")]):_vm._e(),_c('BButton',{staticClass:"mx-2",class:_vm.width < 576 ? 'px-4' : '',style:(_vm.width >= 576 ? 'padding-left: 50px; padding-right: 50px;' : ''),attrs:{"variant":"primary","disabled":this.saveButton === 'Saved' || this.saveButton === 'Saving...'},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(this.saveButton))])],1)]),_c('BRow',{staticClass:"mx-0 mt-60"},[_c('BCol',{attrs:{"sm":"1","md":"2","xl":"3"}}),_c('BCol',{staticClass:"text-left email-alerts",attrs:{"cols":"12","sm":"10","md":"8","xl":"6"}},[(_vm.width >= 576)?_c('h4',{staticClass:"font-weight-bolder"},[_vm._v("Email Alerts")]):_vm._e(),_c('BRow',{class:_vm.width >= 576 ? 'mt-30 ml-30' : 'mb-30'},[_c('BCol',{staticClass:"mt-1 my-auto",class:_vm.width < 576 ? 'pb-4' : '',attrs:{"cols":"12","sm":"6"}},[_c('strong',{staticClass:"text-mediumBlue my-auto font-weight-bold pointerOnHover",on:{"click":function($event){return _vm.emailAlerts.toggleReceiveEmailAlerts()}}},[_c('toggle-button',{staticClass:"my-auto mr-2",attrs:{"color":{
                  checked: _vm.getAppColour(false),
                  unchecked: '#ADADB6',
                  disabled: '#CCCCCC'
                },"value":_vm.emailAlerts.receiveEmailAlerts,"height":18,"width":30,"sync":true,"labels":false},on:{"input":function($event){return _vm.emailAlerts.toggleReceiveEmailAlerts()}}}),_vm._v(" Receive email alerts ")],1)]),(_vm.currentUser)?_c('BCol',{staticClass:"my-auto",staticStyle:{"opacity":"50%"},attrs:{"cols":"12","sm":"6"}},[_c('FormTextInput',{staticClass:"my-auto",attrs:{"inputAttrs":{
                id: 'email',
                label: 'Email',
                labelClass: 'text-mediumBlue',
                type: 'text',
                variant: 'mediumBlue',
                value: _vm.currentUser.email,
                disabled: true
              },"id":"email"},model:{value:(_vm.currentUser.email),callback:function ($$v) {_vm.$set(_vm.currentUser, "email", $$v)},expression:"currentUser.email"}})],1):_vm._e()],1),(_vm.emailAlerts.receiveEmailAlerts)?_c('div',[_c('EmailFrequency',{attrs:{"emailAlerts":_vm.emailAlerts,"width":_vm.width}}),_c('EmailDeliveryOptions',{attrs:{"emailAlerts":_vm.emailAlerts,"width":_vm.width}}),_c('FilterSites',{attrs:{"emailAlerts":_vm.emailAlerts,"width":_vm.width}}),_c('VisibleCustomFields',{attrs:{"width":_vm.width},model:{value:(_vm.emailAlerts.tempPreferences.customFields),callback:function ($$v) {_vm.$set(_vm.emailAlerts.tempPreferences, "customFields", $$v)},expression:"emailAlerts.tempPreferences.customFields"}})],1):_vm._e()],1),_c('BCol',{attrs:{"sm":"1","md":"2","xl":"3"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }