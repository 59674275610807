var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"feedFiltersModal",staticClass:"d-flex",attrs:{"id":"feedFiltersModal","title":`Choose Trend Time Period`,"centered":"","size":"lg","header-bg-variant":"light","body-bg-variant":"light","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 pl-5",attrs:{"id":`feedFiltersModal___BV_modal_title_`}},[_vm._v(" Filters ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeFiltersModal()}}},[_c('BIcon',{attrs:{"icon":"x","scale":"2"}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('BButton',{staticClass:"text-primary hover-white mr-2 my-2 px-5",attrs:{"variant":"outline-mediumGrey"},on:{"click":_vm.clearCustomFilters}},[_vm._v("Clear")]),_c('BButton',{staticClass:"text-white ml-2 my-2 px-5",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyFilters(false)}}},[_vm._v("Apply")]),_c('BButton',{staticClass:"text-white ml-2 my-2 px-5",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyFilters(true)}}},[_vm._v("Save As My Default")])]},proxy:true}])},[_c('div',{staticClass:"px-5",staticStyle:{"max-height":"440px","overflow-x":"auto"}},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Type ")]),_vm._l((['All Types', ..._vm.feedTypes.map(type => type.name)]),function(option){return _c('BButton',{key:option,staticClass:"m-1 border",attrs:{"variant":_vm.selectedFilters.type.includes(option) ||
        (_vm.selectedFilters.type.length == 0 && option == 'All Types')
          ? 'feedColour'
          : 'lightGrey',"pill":"","size":"sm"},on:{"click":function($event){return _vm.updateTypeFilter(option)}}},[_vm._v(_vm._s(option))])}),_c('br'),_c('hr'),_c('BRow',[_c('BCol',{staticClass:"col100 pr-0"},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" "+_vm._s(_vm.toTitleCase(_vm.getPreferredMetricWord()))+" ")])]),_c('BCol',{staticClass:"pl-0",attrs:{"lg":"4","md":"12"}},[_c('b-form-slider',{ref:"range",staticClass:"volumeSlider",class:_vm.selectedFilters[_vm.getFeedAmountFilter()][0] > 0 ||
            _vm.selectedFilters[_vm.getFeedAmountFilter()][1] < _vm.maxVolume
              ? 'selected'
              : 'unselected',attrs:{"range":"","step":500,"min":0,"max":_vm.maxVolume,"trigger-change-event":""},model:{value:(_vm.selectedFilters[_vm.getFeedAmountFilter()]),callback:function ($$v) {_vm.$set(_vm.selectedFilters, _vm.getFeedAmountFilter(), $$v)},expression:"selectedFilters[getFeedAmountFilter()]"}}),_c('br'),_c('p',{staticClass:"fulljustify"},[_vm._v(" "+_vm._s(_vm.getFeedAmount( _vm.selectedFilters[_vm.getFeedAmountFilter()][0], undefined, true ))+_vm._s(_vm.getUnitWithTonnes( _vm.selectedFilters[_vm.getFeedAmountFilter()][0], undefined, true ))+" - "+_vm._s(_vm.getFeedAmount( _vm.selectedFilters[_vm.getFeedAmountFilter()][1], undefined, true ))+_vm._s(_vm.getUnitWithTonnes( _vm.selectedFilters[_vm.getFeedAmountFilter()][1], undefined, true ))+" ")])],1),_c('BCol',{staticClass:"col100 pr-0"},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Capacity ")])]),_c('BCol',{staticClass:"pl-0",attrs:{"lg":"4","md":"12"}},[_c('b-form-slider',{ref:"range",staticClass:"volumeSlider",class:_vm.selectedFilters[_vm.getFeedCapacityFilter()][0] > 0 ||
            _vm.selectedFilters[_vm.getFeedCapacityFilter()][1] < _vm.maxCapacity
              ? 'selected'
              : 'unselected',attrs:{"range":"","step":500,"min":0,"max":_vm.maxCapacity,"trigger-change-event":""},model:{value:(_vm.selectedFilters[_vm.getFeedCapacityFilter()]),callback:function ($$v) {_vm.$set(_vm.selectedFilters, _vm.getFeedCapacityFilter(), $$v)},expression:"selectedFilters[getFeedCapacityFilter()]"}}),_c('p',{staticClass:"fulljustify"},[_vm._v(" "+_vm._s(_vm.getFeedAmount( _vm.selectedFilters[_vm.getFeedCapacityFilter()][0], undefined, true ))+_vm._s(_vm.getUnitWithTonnes( _vm.selectedFilters[_vm.getFeedCapacityFilter()][0], undefined, true ))+" - "+_vm._s(_vm.getFeedAmount( _vm.selectedFilters[_vm.getFeedCapacityFilter()][1], undefined, true ))+_vm._s(_vm.getUnitWithTonnes( _vm.selectedFilters[_vm.getFeedCapacityFilter()][1], undefined, true ))+" ")])],1)],1),_c('hr'),(
        _vm.listSiteCustomFields.length != 0 || _vm.listTankCustomFields.length != 0
      )?_c('label',[_vm._v("Custom Filters")]):_c('label',[_vm._v("No custom filters to show")]),_vm._l((_vm.listSiteCustomFields),function(field,index){return _c('div',{key:index},[_c('p',{staticClass:"inputBlockLabel"},[_vm._v(_vm._s(field.label))]),_vm._l((field.listOptions),function(option){return _c('BButton',{key:option,staticClass:"m-1 border",attrs:{"variant":_vm.isSelected(field, option, 'site') ? 'feedColour' : 'lightGrey',"pill":"","size":"sm"},on:{"click":function($event){return _vm.addFilter(field, option, 'site')}}},[_vm._v(_vm._s(option))])}),_c('hr')],2)}),_vm._l((_vm.listTankCustomFields),function(field,index){return _c('div',{key:index},[_c('p',{staticClass:"inputBlockLabel"},[_vm._v(_vm._s(field.label))]),_vm._l((field.listOptions),function(option){return _c('BButton',{key:option,staticClass:"m-1 border",attrs:{"variant":_vm.isSelected(field, option, 'tank') ? 'feedColour' : 'lightGrey',"pill":"","size":"sm"},on:{"click":function($event){return _vm.addFilter(field, option, 'tank')}}},[_vm._v(_vm._s(option))])}),_c('hr')],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }