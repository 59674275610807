var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BModal',{ref:"fuelFiltersModal",staticClass:"d-flex",attrs:{"id":"fuelFiltersModal","title":`Choose Trend Time Period`,"centered":"","size":"lg","header-bg-variant":"light","body-bg-variant":"light","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 pl-5",attrs:{"id":`fuelFiltersModal___BV_modal_title_`}},[_vm._v(" Filters ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeFiltersModal()}}},[_c('BIcon',{attrs:{"icon":"x","scale":"2"}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('BButton',{staticClass:"text-primary hover-white mr-2 my-2 px-5",attrs:{"variant":"outline-mediumGrey"},on:{"click":_vm.clearCustomFilters}},[_vm._v("Clear")]),_c('BButton',{staticClass:"text-white ml-2 my-2 px-5",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyFilters(false)}}},[_vm._v("Apply")]),_c('BButton',{staticClass:"text-white ml-2 my-2 px-5",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyFilters(true)}}},[_vm._v("Save As My Default")])]},proxy:true}])},[_c('div',{staticClass:"px-5",staticStyle:{"max-height":"440px","overflow-x":"auto"}},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Fuel Type ")]),_vm._l((['All types', ..._vm.fuelTypes.map(type => type.type)]),function(option){return _c('BButton',{key:option,staticClass:"m-1 border",attrs:{"variant":_vm.selectedFilters.type.includes(option) ||
        (_vm.selectedFilters.type.length == 0 && option == 'All types')
          ? 'fuelColour'
          : 'lightGrey',"pill":"","size":"sm"},on:{"click":function($event){return _vm.updateTypeFilter(option)}}},[_vm._v(_vm._s(option))])}),_c('br'),_c('hr'),(
        _vm.selectedFilters.volumeRange[1] != null &&
          _vm.selectedFilters.capacityRange[1] != null &&
          _vm.selectedFilters.ullageRange[1] != null
      )?_c('BRow',[_c('BCol',{staticClass:"col100 pr-0"},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Volume ")])]),_c('BCol',{staticClass:"pl-0",attrs:{"lg":"4","md":"12"}},[_c('b-form-slider',{ref:"range",staticClass:"volumeSlider",class:_vm.selectedFilters.volumeRange[0] > 0 ||
            _vm.selectedFilters.volumeRange[1] < _vm.maxVolume
              ? 'selected'
              : 'unselected',attrs:{"range":"","step":500,"min":0,"max":_vm.maxVolume,"trigger-change-event":""},model:{value:(_vm.selectedFilters.volumeRange),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "volumeRange", $$v)},expression:"selectedFilters.volumeRange"}}),_c('br'),_c('p',{staticClass:"fulljustify"},[_vm._v(" "+_vm._s(_vm.selectedFilters.volumeRange[0].toLocaleString())+"L - "+_vm._s(_vm.selectedFilters.volumeRange[1] ? _vm.selectedFilters.volumeRange[1].toLocaleString() : 0)+"L ")])],1),_c('BCol',{staticClass:"col100 pr-0"},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Capacity ")])]),_c('BCol',{staticClass:"pl-0",attrs:{"lg":"4","md":"12"}},[_c('b-form-slider',{ref:"range",staticClass:"volumeSlider",class:_vm.selectedFilters.capacityRange[0] > 0 ||
            _vm.selectedFilters.capacityRange[1] < _vm.maxCapacity
              ? 'selected'
              : 'unselected',attrs:{"range":"","step":500,"min":0,"max":_vm.maxCapacity,"trigger-change-event":""},model:{value:(_vm.selectedFilters.capacityRange),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "capacityRange", $$v)},expression:"selectedFilters.capacityRange"}}),_c('p',{staticClass:"fulljustify"},[_vm._v(" "+_vm._s(_vm.selectedFilters.capacityRange[0].toLocaleString())+"L - "+_vm._s(_vm.selectedFilters.capacityRange[1] ? _vm.selectedFilters.capacityRange[1].toLocaleString() : 0)+"L ")])],1),_c('BCol',{staticClass:"col100 pr-0"},[_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Ullage ")])]),_c('BCol',{staticClass:"pl-0",attrs:{"lg":"4","md":"12"}},[_c('b-form-slider',{ref:"range",staticClass:"volumeSlider",class:_vm.selectedFilters.ullageRange[0] > 0 ||
            _vm.selectedFilters.ullageRange[1] < _vm.maxUllage
              ? 'selected'
              : 'unselected',attrs:{"range":"","step":500,"min":0,"max":_vm.maxUllage,"trigger-change-event":""},model:{value:(_vm.selectedFilters.ullageRange),callback:function ($$v) {_vm.$set(_vm.selectedFilters, "ullageRange", $$v)},expression:"selectedFilters.ullageRange"}}),_c('p',{staticClass:"fulljustify"},[_vm._v(" "+_vm._s(_vm.selectedFilters.ullageRange[0].toLocaleString())+"L - "+_vm._s(_vm.selectedFilters.ullageRange[1] ? _vm.selectedFilters.ullageRange[1].toLocaleString() : 0)+"L ")])],1)],1):_vm._e(),_c('hr'),_c('p',{staticClass:"inputBlockLabel d-inline mr-3"},[_vm._v(" Warnings ")]),_c('BButton',{staticClass:"m-1 border",attrs:{"variant":!this.selectedFilters.onlyFaults && !this.selectedFilters.onlyOutdated
          ? 'fuelColour'
          : 'lightGrey',"pill":"","size":"sm","title":"All sites"},on:{"click":function($event){return _vm.disableAllWarningsFilters()}}},[_vm._v("All sites")]),_c('BButton',{staticClass:"m-1 border",attrs:{"variant":this.selectedFilters.onlyFaults ? 'fuelColour' : 'lightGrey',"pill":"","size":"sm","title":"Only sites with a repair scheduled"},on:{"click":function($event){return _vm.toggleOnlyFaults()}}},[(!this.selectedFilters.onlyFaults)?_c('img',{staticClass:"mx-3 mb-1",attrs:{"src":require("@/assets/icon_repair2.svg"),"width":"12","height":"12","alt":"Repair"}}):_vm._e(),(this.selectedFilters.onlyFaults)?_c('img',{staticClass:"mx-3 mb-1",attrs:{"src":require("@/assets/icon_repair2_white.svg"),"width":"12","height":"12","alt":"Repair"}}):_vm._e()]),(_vm.outdatedVisible)?_c('BButton',{staticClass:"m-1 border",attrs:{"variant":this.selectedFilters.onlyOutdated ? 'fuelColour' : 'lightGrey',"pill":"","size":"sm","title":"Only sites where the most recent reading for this tank was more than 9 hours ago"},on:{"click":function($event){return _vm.toggleOnlyOutdated()}}},[(!this.selectedFilters.onlyOutdated)?_c('img',{staticClass:"mx-3 mb-1",attrs:{"src":require("@/assets/icon_timer.svg"),"width":"12","height":"12","alt":"Outdated"}}):_vm._e(),(this.selectedFilters.onlyOutdated)?_c('img',{staticClass:"mx-3 mb-1",attrs:{"src":require("@/assets/icon_timer_white.svg"),"width":"12","height":"12","alt":"Outdated"}}):_vm._e()]):_vm._e(),_c('br'),_c('hr'),(
        _vm.listSiteCustomFields.length != 0 || _vm.listTankCustomFields.length != 0
      )?_c('label',[_vm._v("Custom Filters")]):_c('label',[_vm._v("No custom filters to show")]),_vm._l((_vm.listSiteCustomFields),function(field){return _c('div',{key:field.name},[_c('p',{staticClass:"inputBlockLabel"},[_vm._v(_vm._s(field.label))]),_vm._l((field.listOptions),function(option){return _c('BButton',{key:option,staticClass:"m-1 border",attrs:{"variant":_vm.isSelected(field, option, 'site') ? 'fuelColour' : 'lightGrey',"pill":"","size":"sm"},on:{"click":function($event){return _vm.addFilter(field, option, 'site')}}},[_vm._v(_vm._s(option))])}),_c('hr')],2)}),_vm._l((_vm.listTankCustomFields),function(field){return _c('div',{key:field.name},[_c('p',{staticClass:"inputBlockLabel"},[_vm._v(_vm._s(field.label))]),_vm._l((field.listOptions),function(option){return _c('BButton',{key:option,staticClass:"m-1 border",attrs:{"variant":_vm.isSelected(field, option, 'tank') ? 'fuelColour' : 'lightGrey',"pill":"","size":"sm"},on:{"click":function($event){return _vm.addFilter(field, option, 'tank')}}},[_vm._v(_vm._s(option))])}),_c('hr')],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }