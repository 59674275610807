
import FilterTextInput from '@/components/filters/FilterTextInput.vue';
import { FetchFuelTypes } from '@/services/api/ProductApi';
import { outdatedFunctionality } from '@/store/Globals';
import CustomField from '@/store/models/CustomField';
import { FuelFilters, SavedFuelFilters } from '@/store/models/Filters';
import FuelType from '@/store/models/FuelType';
import CustomStore from '@/store/modules/CustomStore';
import { getPageName } from '@/utils/PageUtils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({ components: { FilterTextInput } })
export default class FuelFiltersModal extends Vue {
  @Prop() selectedPropSiteFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop() selectedPropTankFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop() propFilters!: FuelFilters;
  @Prop() maxVolume!: number;
  @Prop() maxCapacity!: number;
  @Prop() maxUllage!: number;
  @Prop() width!: number;

  public selectedSiteFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedTankFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedFilters: FuelFilters = {
    type: [],
    volumeRange: [0, 0],
    capacityRange: [0, 0],
    ullageRange: [0, 0],
    customSiteFilters: [],
    customEntityFilters: [],
    onlyFaults: false,
    onlyOutdated: false
  };

  public updated = false;

  public textInputFilters: {
    minVolume: number;
    maxVolume: number;
    minCapacity: number;
    maxCapacity: number;
  } = { minVolume: 0, maxVolume: 0, minCapacity: 0, maxCapacity: 0 };
  public fuelTypes: FuelType[] = [];

  get outdatedVisible() {
    return outdatedFunctionality.visible && getPageName() != 'email-alerts';
  }

  mounted() {
    if (this.propFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(this.propFilters));
    }
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
    if (this.selectedPropTankFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropTankFilters;
    }
  }

  created() {
    FetchFuelTypes().then(data => {
      this.fuelTypes = data;
    });
  }

  @Watch('selectedPropSiteFilters')
  sitePropFilterChanged() {
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
  }

  @Watch('selectedPropTankFilters')
  tankPropFilterChanged() {
    if (this.selectedPropTankFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropTankFilters;
    }
  }

  @Watch('propFilters', { deep: true })
  filtersUpdated() {
    if (this.propFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(this.propFilters));
    }
  }

  @Watch('maxVolume')
  maxVolumeLoaded(value: number, oldValue: number) {
    if (this.selectedFilters.volumeRange[1] == oldValue) {
      this.selectedFilters.volumeRange[1] = value;
    } else if (this.selectedFilters.volumeRange[1] == 0) {
      this.selectedFilters.volumeRange[1] = this.maxVolume;
    }
  }

  @Watch('maxCapacity')
  maxCapacityLoaded(value: number, oldValue: number) {
    if (this.selectedFilters.capacityRange[1] == oldValue) {
      this.selectedFilters.capacityRange[1] = value;
    } else if (this.selectedFilters.capacityRange[1] == 0) {
      this.selectedFilters.capacityRange[1] = this.maxCapacity;
    }
  }

  @Watch('maxUllage')
  maxUllageLoaded(value: number, oldValue: number) {
    if (this.selectedFilters.ullageRange[1] == oldValue) {
      this.selectedFilters.ullageRange[1] = value;
    } else if (this.selectedFilters.ullageRange[1] == 0) {
      this.selectedFilters.ullageRange[1] = this.maxUllage;
    }
  }

  get listSiteCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customFields)
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered?.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }
  get listTankCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customEntityFields)
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered?.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }

  public isSelected(field: CustomField, selection: string, level: string) {
    let includes = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters?.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters?.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    }
    return includes;
  }

  public addFilter(field: CustomField, selection: string, level: string) {
    this.updated = true;
    let includes = false;
    let selectionAlreadyInField = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters?.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });
      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customSiteFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customSiteFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customSiteFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customSiteFilters = this.selectedFilters.customSiteFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters?.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });

      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customEntityFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customEntityFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customEntityFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customEntityFilters = this.selectedFilters.customEntityFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    }
  }

  public updateTypeFilter(newType: string) {
    if (newType == 'All types') {
      this.selectedFilters.type = [];
    } else if (this.selectedFilters.type.includes(newType)) {
      this.selectedFilters.type = this.selectedFilters.type.filter(type => {
        return type != newType;
      });
    } else {
      this.selectedFilters.type.push(newType);
    }
  }

  public toggleOnlyFaults() {
    this.selectedFilters.onlyFaults = !this.selectedFilters.onlyFaults;
  }

  public toggleOnlyOutdated() {
    this.selectedFilters.onlyOutdated = !this.selectedFilters.onlyOutdated;
  }

  public disableAllWarningsFilters() {
    this.selectedFilters.onlyFaults = false;
    this.selectedFilters.onlyOutdated = false;
  }

  public applyFilters(applyAndSave = false) {
    const alteredFilters: SavedFuelFilters = JSON.parse(
      JSON.stringify(this.selectedFilters)
    );
    if (this.selectedFilters.volumeRange[1] == this.maxVolume) {
      alteredFilters.volumeRange[1] = null;
    }
    if (this.selectedFilters.capacityRange[1] == this.maxCapacity) {
      alteredFilters.capacityRange[1] = null;
    }
    if (this.selectedFilters.ullageRange[1] == this.maxUllage) {
      alteredFilters.ullageRange[1] = null;
    }
    if (
      alteredFilters.customEntityFilters == undefined &&
      (alteredFilters as any).customTankFilters != undefined
    ) {
      alteredFilters.customEntityFilters = (alteredFilters as any).customTankFilters;
      delete (alteredFilters as any).customTankFilters;
    }
    this.$emit(
      'moreFiltersUpdated',
      alteredFilters,
      this.selectedFilters,
      applyAndSave
    );
    this.closeFiltersModal();
  }
  public clearCustomFilters() {
    this.selectedFilters = {
      type: [],
      volumeRange: [0, this.maxVolume],
      capacityRange: [0, this.maxCapacity],
      ullageRange: [0, this.maxUllage],
      customSiteFilters: [],
      customEntityFilters: [],
      onlyFaults: false,
      onlyOutdated: false
    };
    this.$emit('clearFilters');
    this.applyFilters(false);
    this.closeFiltersModal();
  }

  public closeFiltersModal() {
    this.$root.$emit('bv::hide::modal', 'fuelFiltersModal', '#btnHide');
  }
}
