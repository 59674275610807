import Preferences, {
  AppAlertSummaryEmail,
  CustomFieldObjectPreferences,
  FeedAlertSummaryEmail,
  FuelAlertSummaryEmail,
  MilkAlertSummaryEmail,
  PageEmailPreferences,
  pageEmailPreferencesDefaults
} from '@/services/api/models/Preferences';
import { storeState } from '@/store/Globals';
import { FilterListFormat } from '@/store/models/EmailAlerts';
import { AppFilters } from '@/store/models/Filters';
import CustomStore from '@/store/modules/CustomStore';
import User from '@/store/modules/User';
import cloneDeep from 'lodash.clonedeep';
import { isApp } from '../AppName';
import { Constants } from '../Constants';
import {
  alterCustomFiltersFromSaved,
  convertCustomFieldsToString,
  convertFixedScheduleFromHHmm,
  convertFixedScheduleToHHmm,
  formatFilterListItem,
  getAppSummaryEmail
} from '../EmailAlertsUtils';
import { alterCustomFiltersForSaving, isFiltersDefault } from '../FiltersUtils';
import { getAppFilter } from '../GenericUtils';

export class EmailAlertsClass {
  public listFilters: FilterListFormat[] | null = null;
  public tempPreferences!: PageEmailPreferences; //The editable preferences before saving
  public emailFrequency = 'recur';
  public fixedChecked = [0, 1, 2, 3, 4];
  public customFiltersEnabled = false;
  public loading = true;

  constructor() {
    this.setDefaults();
    const userPreferences = User._currentUser
      ?.preferences as Preferences | null;
    if (userPreferences) {
      const savedEmailPreferences = getAppSummaryEmail(userPreferences);
      if (savedEmailPreferences) {
        this.setupFromSaved(savedEmailPreferences);
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  public generateListFilters(alteredFilters: AppFilters) {
    this.listFilters = [];
    this.listFilters?.push(...formatFilterListItem(alteredFilters));
    this.tempPreferences.customFilter = alteredFilters;
  }

  get receiveEmailAlerts() {
    return !!this.tempPreferences.partnerId;
  }

  public async savePreferences() {
    const userPreferences = cloneDeep(
      User._currentUser?.preferences
    ) as Preferences | null;

    const preferences: PageEmailPreferences | AppAlertSummaryEmail = cloneDeep(
      this.tempPreferences
    );
    if (userPreferences && preferences) {
      preferences.customFields = convertCustomFieldsToString(
        preferences.customFields as CustomFieldObjectPreferences
      );
      if (this.emailFrequency == 'recur') {
        preferences.fixedSchedule = null;
      } else if (this.emailFrequency == 'preset') {
        const fixedScheduleTemp = preferences.fixedSchedule?.filter(
          (time, index) => {
            return this.fixedChecked.includes(index);
          }
        );
        preferences.fixedSchedule = convertFixedScheduleToHHmm(
          Array.from(new Set(fixedScheduleTemp))
        );
        preferences.periodSchedule = null;
      }
      if (!isApp(Constants.PRODUCT_TYPE_FUEL)) {
        delete preferences.customFields.tanks;
      }
      if (!isApp(Constants.PRODUCT_TYPE_MILK)) {
        delete preferences.customFields.vats;
      }
      if (!isApp(Constants.PRODUCT_TYPE_FEED)) {
        delete preferences.customFields.feed_tanks;
      }
      if (
        this.customFiltersEnabled &&
        this.listFilters &&
        this.listFilters?.length != 0
      ) {
        preferences.customFilter = await alterCustomFiltersForSaving(
          this.tempPreferences.customFilter as AppFilters
        );
      } else {
        preferences.customFilter = pageEmailPreferencesDefaults().customFilter;
      }

      if (isApp(Constants.PRODUCT_TYPE_MILK) && userPreferences.partner.milk) {
        userPreferences.partner.milk.alertSummaryEmail = preferences as MilkAlertSummaryEmail;
      } else if (
        isApp(Constants.PRODUCT_TYPE_FUEL) &&
        userPreferences.partner.fuel
      ) {
        userPreferences.partner.fuel.alertSummaryEmail = preferences as FuelAlertSummaryEmail;
      } else if (
        isApp(Constants.PRODUCT_TYPE_FEED) &&
        userPreferences.partner.feed
      ) {
        userPreferences.partner.feed.alertSummaryEmail = preferences as FeedAlertSummaryEmail;
      }
    }
    delete userPreferences?.partner?.milk?.alertSummaryEmail.lastRunTs;
    delete userPreferences?.partner?.fuel?.alertSummaryEmail.lastRunTs;
    delete userPreferences?.partner?.feed?.alertSummaryEmail.lastRunTs;
    return (
      (await User.changeUserDetails({
        id: User._currentUser?.id ?? -1,
        params: { preferences: userPreferences }
      })) == -1
    );
  }

  public async setupFromSaved(savedEmailPreferences: AppAlertSummaryEmail) {
    this.tempPreferences.customFilter = await alterCustomFiltersFromSaved(
      getAppFilter().filters as AppFilters
    );
    this.tempPreferences.partnerId = savedEmailPreferences.partnerId;
    this.emailFrequency =
      savedEmailPreferences.fixedSchedule &&
      savedEmailPreferences.fixedSchedule.length != 0
        ? 'preset'
        : 'recur';
    this.setFixedScheduleFromSaved(savedEmailPreferences);
    this.setPeriodScheduleFromSaved(savedEmailPreferences);
    if (
      !(
        savedEmailPreferences.customFilter &&
        isFiltersDefault(savedEmailPreferences.customFilter)
      )
    ) {
      this.customFiltersEnabled = true;
      await this.setFiltersFromSaved(savedEmailPreferences);
    }
    this.setCustomFieldsFromSaved();
    this.setOnlySendNewFromSaved(savedEmailPreferences);
    this.loading = false;
  }

  public setCustomFieldsFromSaved() {
    const userPreferences = User._currentUser
      ?.preferences as Preferences | null;
    if (userPreferences && !storeState.loading) {
      const savedEmailPreferences = getAppSummaryEmail(userPreferences);
      if (
        savedEmailPreferences &&
        (savedEmailPreferences.customFields.products.length != 0 ||
          savedEmailPreferences.customFields.tanks?.length != 0 ||
          savedEmailPreferences.customFields.vats?.length != 0 ||
          savedEmailPreferences.customFields.feed_tanks?.length != 0)
      ) {
        this.tempPreferences.customFields.products =
          CustomStore.customFields?.filter(field =>
            savedEmailPreferences.customFields.products.includes(field.name)
          ) ?? [];
        this.tempPreferences.customFields.tanks =
          CustomStore.customEntityFields?.filter(field =>
            savedEmailPreferences.customFields.tanks?.includes(field.name)
          ) ?? [];
        this.tempPreferences.customFields.vats =
          CustomStore.customEntityFields?.filter(field =>
            savedEmailPreferences.customFields.vats?.includes(field.name)
          ) ?? [];
        this.tempPreferences.customFields.feed_tanks =
          CustomStore.customEntityFields?.filter(field =>
            savedEmailPreferences.customFields.feed_tanks?.includes(field.name)
          ) ?? [];
      }
    }
  }

  public setDefaults() {
    this.tempPreferences = pageEmailPreferencesDefaults();
    this.tempPreferences.periodSchedule = 60;
    this.tempPreferences.fixedSchedule = [
      '5:30 am',
      '9:30 am',
      '1:30 pm',
      '5:30 pm',
      '9:30 pm'
    ];
  }

  public setFixedScheduleFromSaved(
    savedEmailPreferences: AppAlertSummaryEmail
  ) {
    if (
      savedEmailPreferences.fixedSchedule &&
      savedEmailPreferences.fixedSchedule.length
    ) {
      const fixedScheduleTemp = convertFixedScheduleFromHHmm(
        savedEmailPreferences.fixedSchedule
      );
      const len = fixedScheduleTemp.length;
      if (len < 5) {
        this.fixedChecked = this.fixedChecked.filter(num => num < len);
        if (this.tempPreferences.fixedSchedule) {
          for (let i = len; i < 5; i++) {
            fixedScheduleTemp.push(this.tempPreferences.fixedSchedule[i]); //adding extra placeholders
          }
        }
      }
      this.tempPreferences.fixedSchedule = fixedScheduleTemp;
    }
  }

  public async setFiltersFromSaved(
    savedEmailPreferences: AppAlertSummaryEmail
  ) {
    if (
      !(
        savedEmailPreferences.customFilter &&
        isFiltersDefault(savedEmailPreferences.customFilter)
      )
    ) {
      this.tempPreferences.customFilter = await alterCustomFiltersFromSaved({
        ...savedEmailPreferences.customFilter
      });
      this.generateListFilters(this.tempPreferences.customFilter);
    }
  }

  public setOnlySendNewFromSaved(savedEmailPreferences: AppAlertSummaryEmail) {
    this.tempPreferences.onlySendNew = savedEmailPreferences.onlySendNew;
  }

  public setPeriodScheduleFromSaved(
    savedEmailPreferences: AppAlertSummaryEmail
  ) {
    if (savedEmailPreferences.periodSchedule) {
      this.tempPreferences.periodSchedule =
        savedEmailPreferences.periodSchedule;
    }
  }

  public toggleReceiveEmailAlerts() {
    if (this.tempPreferences.partnerId) {
      this.tempPreferences.partnerId = null;
    } else {
      this.tempPreferences.partnerId = User.orgId ?? null;
    }
  }
}
